import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo_color.svg";

const Header = () => {
  return (
    <div className="container">
      <div className="navbar">
        <Link className="mt-2" to="/">
          <img src={logo} width={140} />
        </Link>

        <div className="nav">
          <Link className="nav-item" to="/sobre">
            Sobre
          </Link>
          <Link className="nav-item" to="/inquerito">
            Inquérito
          </Link>
          <Link className="nav-item-btn" to="/aderir">
            Aderir
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
