import { IconArrowRight } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import rgpd from "../assets/documents/RegimeGeralProtecaoDados.pdf";
import logo from "../assets/logo_color.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <Link to="/">
              <img src={logo} width={140} />
            </Link>
          </div>
          <div className="col-md-2 mt-5 mt-md-2">
            <div className="nav flex-column align-items-start gap-3">
              <Link className="nav-item" to="/sobre">
                Sobre
              </Link>
              <Link className="nav-item" to="/inquerito">
                Inquérito
              </Link>
              <Link className="nav-item" to="/aderir">
                Aderir
              </Link>

              <a
                className="nav-item"
                role="button"
                href={rgpd}
                rel="noopener noreferrer"
                //onClick={() => window.open("file:///" + rgpd)}>
                target="_blank">
                RGPD
              </a>
            </div>
          </div>
          <div className="col-md-5 mt-5 mt-md-2 text-sm">
            Medida financiada no âmbito do Plano de Promoção da Eficiência no
            Consumo de Energia, aprovado pela ERSE-Entidade Reguladora dos
            Serviços Energéticos.
            <div className="companies gap-5 mt-3">
              <a
                href="https://www.erse.pt/inicio/"
                target="_blank"
                rel="noreferrer">
                <img
                  src="https://simulador.potencia.erse.pt/static/share/2022-12/2932bd51-18a3-4c09-ac91-dd5c676af085/61c95db7-8cba-40be-9cf1-b523355f6b3c/logo/cd77123f-8519-4bea-8ad4-2d6ca1d16a40/logo_erse-.png"
                  alt="erse"
                />
              </a>
              <a
                href="https://www.usenergy.pt"
                target="_blank"
                rel="noreferrer">
                <img
                  src="https://www.usenergy.pt/img/logo.png"
                  alt="usenergy"
                />
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-5 mt-md-2">
            <div className="d-flex justify-content-md-end">
              <a href="mailto:geral@economizarenergia.pt" className="primary-btn">
                geral@economizarenergia.pt{" "}
                <IconArrowRight size={24} color="#eb595e" stroke={1} />
              </a>
            </div>
          </div>
        </div>
        <div className="copyright text-md-center mt-5 pb-3 text-secondary text-xs">
          Copyright &copy; 2023 Economizar Energia
        </div>
      </div>
    </div>
  );
};

export default Footer;
